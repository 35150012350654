import { DownloadSimple, Check, Plus, X, Tray } from '@phosphor-icons/react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useClient } from 'urql';
import { toast } from 'react-hot-toast';

import { Button } from '../../../components/button/Button';
import { ISimpleComboboxItem } from '../../../components/combobox/SimpleCombobox';
import { Input } from '../../../components/input/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Pagination } from '../../../components/pagination/Pagination';
import { SearchType, SearchWithType } from '../../../components/search-with-type/SearchWithType';
import { SimpleSelect } from '../../../components/select/SimpleSelect';
import { StatusText } from '../../../components/StatusText';
import { ITableHeader, Table } from '../../../components/table/Table';
import {
  GetOrdersDocument,
  GetOrdersQuery,
  GetOrdersQueryVariables,
  OrderDateFilterType,
  OrderDocumentType,
  OrderLineStopType,
  OrdersFilterInputData,
  OrderStatus,
  SaleStatus,
  PurchaseStatus,
  useExportOrdersToExcelMutation,
  SortDirection,
  useGetOrdersCountQuery,
  ExportOrdersExcelDownloadUrlDocument,
  ExportOrdersExcelDownloadUrlQuery,
  ExportOrdersExcelDownloadUrlQueryVariables,
  BusinessUnit,
  UserRole,
} from '../../../generated/graphql';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { formatDate, formatInputTime } from '../../../utils/date';
import { formatNumber, parseIntOrNull } from '../../../utils/number';
import {
  BUSINESS_UNIT_OPTIONS,
  ORDER_STATUS_COLOR,
  ORDER_STATUS_OPTIONS,
  PURCHASE_STATUS_COLOR,
  PURCHASE_STATUS_OPTIONS,
  SALE_STATUS_COLOR,
  SALE_STATUS_OPTIONS,
} from '../constants';
import { calculateLinesTotalExclVat } from '../utils/price';
import { getDisplayError } from '../../../utils/get-display-error';
import { downloadUrl } from '../../../utils/download';
import { useSearchParam } from '../../../hooks/useSearchParam';
import { useAuth } from '../../../contexts/auth-context';
import { OrderLocationCombobox, ComboValue as LocationComboValue } from '../components/OrderLocationCombobox';
import { LinkButton } from '../../../components/button/ButtonLink';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { captureException } from '@sentry/react';
import { sleep } from '@utils/sleep';
import { InputWrapper } from 'components/InputWrapper';
import { roleToNumber } from 'src/app/auth/roles.client';

const ORDERS_HEADERS: ITableHeader[] = [
  {
    id: 'id',
    name: 'Order Nr',
  },
  {
    id: 'customer',
    name: 'Klant',
    width: '200px',
  },
  {
    id: 'customerRef',
    name: 'Klantref',
  },
  {
    id: 'loadLocation',
    name: 'Laadplaats',
  },
  {
    id: 'unloadLocation',
    name: 'Bestemming',
  },
  {
    id: 'purchaseAmount',
    name: 'Aankooptotaal',
  },
  {
    id: 'saleAmount',
    name: 'Facturatietotaal',
  },
  {
    id: 'status',
    name: 'Status',
  },
  {
    id: 'purchaseStatus',
    name: 'AK Status',
  },
  {
    id: 'saleStatus',
    name: 'VK Status',
  },
  {
    id: 'cmr',
    name: 'CMR',
  },
  {
    id: 'loadDate',
    name: 'Laaddatum',
  },
  {
    id: 'loadTime',
    name: 'Laaduur',
  },
  {
    id: 'unloadDate',
    name: 'Losdatum',
  },
  {
    id: 'unloadTime',
    name: 'Losuur',
  },
  {
    id: 'supplier',
    name: 'Vervoerder',
    width: '200px',
  },
];

const searchTypes: SearchType[] = [
  {
    label: 'Order referentie',
    value: 'orderNumber',
  },
  {
    label: 'Klant naam',
    value: 'customerName',
  },
  {
    label: 'Factuurreferentie klant',
    value: 'customerRef',
  },
  {
    label: 'Vervoerder naam',
    value: 'supplierName',
  },
];

const DATE_TYPE_OPTIONS = [
  {
    key: OrderDateFilterType.Unload,
    name: 'Losdatum',
  },
  {
    key: OrderDateFilterType.Load,
    name: 'Laaddatum',
  },
  {
    key: OrderDateFilterType.Creation,
    name: 'Ingave datum',
  },
];

const CMR_FILTER_OPTIONS = [
  {
    key: 'all',
    name: 'Alle',
  },
  {
    key: 'has-cmr',
    name: 'Heeft CMR',
  },
  {
    key: 'no-cmr',
    name: 'Heeft geen CMR',
  },
];

enum FilterType {
  StartDate = 'start-date',
  EndDate = 'end-date',
  SearchOne = 'search-one',
  SearchTwo = 'search-two',
  OrderStatus = 'order-status',
  SaleStatus = 'sale-status',
  PurchaseStatus = 'purchase-status',
  DateType = 'date-type',
  CmrFilter = 'cmr-filter',
  LoadLocation = 'load-location',
  UnloadLocation = 'unload-location',
  BusinessUnit = 'bu',
}

interface IOrderCountProps {
  filters: OrdersFilterInputData;
}

const OrderCount: React.FC<IOrderCountProps> = (props) => {
  const { filters } = props;
  const [result] = useGetOrdersCountQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      filters,
    },
  });

  return <div className="text-dark-01">{`Totaal aantal orders: ${result.data?.ordersCount ?? 0}`}</div>;
};

const OrdersPage = () => {
  const client = useClient();
  const { me } = useAuth();
  const [_searchParams, setSearchParams] = useSearchParams();
  const [, exportOrders] = useExportOrdersToExcelMutation();
  const [isExporting, setIsExporting] = useState(false);

  // Filter values
  const [searchOne, setSearchOne] = useSearchParam(FilterType.SearchOne, {
    value: '',
    type: searchTypes[0].value,
  });
  const [searchTwo, setSearchTwo] = useSearchParam(FilterType.SearchTwo, {
    value: '',
    type: searchTypes[1].value,
  });
  const [startDate, setStartDate] = useSearchParam(FilterType.StartDate, '');
  const [endDate, setEndDate] = useSearchParam(FilterType.EndDate, '');
  const [selectedDateType, setSelectedDateType] = useSearchParam<ISimpleComboboxItem>(
    FilterType.DateType,
    DATE_TYPE_OPTIONS[0],
  );
  const [selectedOrderStatus, setSelectedOrderStatus] = useSearchParam<ISimpleComboboxItem | null>(
    FilterType.OrderStatus,
    null,
  );
  const [selectedSaleStatus, setSelectedSaleStatus] = useSearchParam<ISimpleComboboxItem | null>(
    FilterType.SaleStatus,
    null,
  );
  const [selectedPurchaseStatus, setSelectedPurchaseStatus] = useSearchParam<ISimpleComboboxItem | null>(
    FilterType.PurchaseStatus,
    null,
  );
  const [selectedCmrFilter, setSelectedCmrFilter] = useSearchParam<ISimpleComboboxItem | null>(
    FilterType.CmrFilter,
    CMR_FILTER_OPTIONS[0],
  );
  const [loadLocationFilter, setLoadLocationFilter] = useSearchParam<LocationComboValue | null>(
    FilterType.LoadLocation,
    null,
  );
  const [unloadLocationFilter, setUnloadLocationFilter] = useSearchParam<LocationComboValue | null>(
    FilterType.UnloadLocation,
    null,
  );
  const [businessUnitFilter, setBusinessUnitFilter] = useSearchParam<BusinessUnit | null>(
    FilterType.BusinessUnit,
    null,
  );

  const [filters, setFilters] = useState<OrdersFilterInputData>({});
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const result = await client
        .query<GetOrdersQuery, GetOrdersQueryVariables>(
          GetOrdersDocument,
          {
            id: variables.cursor,
            take: variables.take,
            filters,
            orderBy: {
              orderNumber: SortDirection.Desc,
            },
          },
          {
            requestPolicy: 'cache-and-network',
          },
        )
        .toPromise();

      if (result.error) {
        throw result.error;
      }

      return result.data?.orders ?? [];
    },
    [client, filters],
  );
  const page = usePagination({
    key: 'id',
    pageSize: 50,
    initialCursor: undefined,
    fetcher: pageFetcher,
  });

  useEffect(() => {
    page.reset();
  }, [filters]);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      const filters: OrdersFilterInputData = {
        dateType: selectedDateType.key as OrderDateFilterType,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        status: selectedOrderStatus?.key as OrderStatus | undefined,
        saleStatus: selectedSaleStatus?.key as SaleStatus | undefined,
        purchaseStatus: selectedPurchaseStatus?.key as PurchaseStatus | undefined,
        hasCMR: selectedCmrFilter?.key === 'has-cmr' ? true : selectedCmrFilter?.key === 'no-cmr' ? false : undefined,
        loadLocationCountry: loadLocationFilter?.country,
        loadLocationPostalCode: loadLocationFilter?.postalCode,
        unloadLocationCountry: unloadLocationFilter?.country,
        unloadLocationPostalCode: unloadLocationFilter?.postalCode,
        businessUnit: businessUnitFilter,
      };

      console.log(filters);

      const setSearch = (value: string, type: string) => {
        switch (type) {
          case 'customerId':
            filters.customerId = parseIntOrNull(value) ?? undefined;
            break;
          case 'customerName':
            filters.customerName = value;
            break;
          case 'orderNumber':
            filters.orderNumber = value;
            break;
          case 'customerRef':
            filters.customerRef = value;
            break;
          case 'supplierName':
            filters.supplierName = value;
            break;
        }
      };

      if (searchOne.value) {
        setSearch(searchOne.value, searchOne.type);
      }

      if (searchTwo.value) {
        setSearch(searchTwo.value, searchTwo.type);
      }

      setFilters(filters);
    }, 100);
    return () => clearTimeout(timeoutRef);
  }, [
    searchOne,
    searchTwo,
    startDate,
    endDate,
    selectedDateType,
    selectedOrderStatus,
    selectedSaleStatus,
    selectedPurchaseStatus,
    selectedCmrFilter,
    loadLocationFilter,
    unloadLocationFilter,
    businessUnitFilter,
  ]);

  const isAdmin = roleToNumber(me.role) >= roleToNumber(UserRole.Admin);

  return (
    <>
      <PageHeader title="Orders" />

      <div>
        <PageHeading
          leftSide={<Breadcrumb currentItem="Orders" />}
          rightSide={
            <div className="flex items-end gap-4">
              <div>
                <LinkButton to="incoming" iconLeft={<Tray className="button-icon" />}>
                  Auto-verwerking
                </LinkButton>
              </div>
              {isAdmin && (
                <div>
                  <Button
                    onTrigger={async () => {
                      setIsExporting(true);
                      try {
                        const res = await exportOrders({
                          filters,
                        });
                        if (res.error) {
                          throw res.error;
                        }
                        const jobId = res.data?.exportOrdersExcel;
                        if (!jobId) {
                          throw new Error('Order export did not return a valid job id');
                        }

                        while (true) {
                          console.log('Pinging for export status');
                          const result = await client
                            .query<ExportOrdersExcelDownloadUrlQuery, ExportOrdersExcelDownloadUrlQueryVariables>(
                              ExportOrdersExcelDownloadUrlDocument,
                              {
                                id: jobId,
                              },
                              {
                                requestPolicy: 'network-only',
                              },
                            )
                            .toPromise();

                          if (result.error) {
                            throw result.error;
                          }

                          const url = result.data?.exportOrdersExcelDownloadUrl;
                          if (url) {
                            toast.success('Orders export aangemaakt, wordt nu gedownload');
                            downloadUrl(url, {
                              filename: 'orders.xlsx',
                              openInNewTab: true,
                            });
                            break;
                          }

                          await sleep(2000);
                        }
                      } catch (err) {
                        captureException(err);
                        toast.error('Order export mislukt: ' + getDisplayError(err));
                      }
                      setIsExporting(false);
                    }}
                    isLoading={isExporting}
                    iconLeft={<DownloadSimple className="button-icon" />}
                  >
                    Export Excel
                  </Button>
                </div>
              )}
              <div>
                <LinkButton to="new-from-template" iconLeft={<Plus className="button-icon" />}>
                  Nieuwe orders
                </LinkButton>
              </div>
              <div>
                <LinkButton to="new" color="primary" iconLeft={<Plus className="button-icon" />}>
                  Nieuwe order
                </LinkButton>
              </div>
            </div>
          }
        />

        <div className="flex flex-wrap justify-end items-end gap-4 mb-4 px-4 pb-8">
          <div className="flex-1">
            <SearchWithType
              value={searchOne.value}
              onChange={(val) => setSearchOne((prev) => ({ ...prev, value: val }))}
              types={searchTypes}
              selectedType={searchOne.type}
              onTypeChange={(val) => setSearchOne((prev) => ({ ...prev, type: val }))}
            />
          </div>

          <div className="flex-1">
            <SearchWithType
              value={searchTwo.value}
              onChange={(val) => setSearchTwo((prev) => ({ ...prev, value: val }))}
              types={searchTypes}
              selectedType={searchTwo.type}
              onTypeChange={(val) => setSearchTwo((prev) => ({ ...prev, type: val }))}
            />
          </div>

          <div>
            <label>
              <div className="label-text">Van</div>
              <Input type="date" value={startDate} onChange={setStartDate} />
            </label>
          </div>

          <div>
            <label>
              <div className="label-text">Tot</div>
              <Input type="date" value={endDate} onChange={setEndDate} />
            </label>
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">Type datum</div>
              <SimpleSelect
                selectedItem={selectedDateType}
                items={[...DATE_TYPE_OPTIONS]}
                onSelect={(val) => {
                  if (val) {
                    setSelectedDateType(val);
                  }
                }}
              />
            </label>
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">Order Status</div>
              <SimpleSelect
                selectedItem={selectedOrderStatus}
                items={[...ORDER_STATUS_OPTIONS]}
                isOptional={true}
                onSelect={(val) => {
                  if (val?.key === selectedOrderStatus?.key) {
                    setSelectedOrderStatus(null);
                  } else {
                    setSelectedOrderStatus(val);
                  }
                }}
              />
            </label>
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">Verkoop Status</div>
              <SimpleSelect
                selectedItem={selectedSaleStatus}
                items={[...SALE_STATUS_OPTIONS]}
                isOptional={true}
                onSelect={(val) => {
                  if (val?.key === selectedSaleStatus?.key) {
                    setSelectedSaleStatus(null);
                  } else {
                    setSelectedSaleStatus(val);
                  }
                }}
              />
            </label>
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">Aankoop Status</div>
              <SimpleSelect
                selectedItem={selectedPurchaseStatus}
                items={[...PURCHASE_STATUS_OPTIONS]}
                isOptional={true}
                onSelect={(val) => {
                  if (val?.key === selectedPurchaseStatus?.key) {
                    setSelectedPurchaseStatus(null);
                  } else {
                    setSelectedPurchaseStatus(val);
                  }
                }}
              />
            </label>
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">CMR</div>
              <SimpleSelect
                selectedItem={selectedCmrFilter}
                items={[...CMR_FILTER_OPTIONS]}
                isOptional={false}
                onSelect={(val) => {
                  if (val) {
                    setSelectedCmrFilter(val);
                  }
                }}
              />
            </label>
          </div>

          <div className="w-48">
            <OrderLocationCombobox
              orderLineStopType={OrderLineStopType.Load}
              labelText="Laad locatie"
              value={loadLocationFilter}
              hideInvalid={true}
              isNullable={true}
              onChange={(val) => {
                setLoadLocationFilter(val);
              }}
            />
          </div>

          <div className="w-48">
            <OrderLocationCombobox
              orderLineStopType={OrderLineStopType.Unload}
              labelText="Los locatie"
              value={unloadLocationFilter}
              hideInvalid={true}
              isNullable={true}
              onChange={(val) => {
                setUnloadLocationFilter(val);
              }}
            />
          </div>

          <div className="w-48">
            <label>
              <div className="label-text">Business Unit</div>
              <SimpleSelect
                onSelect={(value) => {
                  if (!value) {
                    setBusinessUnitFilter(null);
                  } else {
                    setBusinessUnitFilter(value.key as BusinessUnit);
                  }
                }}
                selectedItem={BUSINESS_UNIT_OPTIONS.find((v) => v.key === filters.businessUnit) ?? null}
                items={BUSINESS_UNIT_OPTIONS}
                isOptional={true}
              />
            </label>
          </div>

          <div>
            <Button onTrigger={() => setSearchParams({})}>Wis alle filters</Button>
          </div>
        </div>

        <div className="w-full-content overflow-auto">
          <Table
            idKey="id"
            headers={ORDERS_HEADERS}
            data={page.data}
            mapData={(order) => {
              const orderStatusColor = ORDER_STATUS_COLOR[order.status] ?? 'black';
              const orderStatusText = ORDER_STATUS_OPTIONS.find((i) => i.key === order.status)?.name ?? order.status;
              const purchaseStatusColor = PURCHASE_STATUS_COLOR[order.purchaseStatus] ?? 'black';
              const purchaseStatusText =
                PURCHASE_STATUS_OPTIONS.find((i) => i.key === order.purchaseStatus)?.name ?? order.purchaseStatus;
              const saleStatusColor = SALE_STATUS_COLOR[order.saleStatus] ?? 'black';
              const saleStatusText =
                SALE_STATUS_OPTIONS.find((i) => i.key === order.saleStatus)?.name ?? order.saleStatus;
              const loadStops = order.lines.map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Load)).flat();
              const unloadStops = order.lines
                .map((v) => v.stops.filter((s) => s.type === OrderLineStopType.Unload))
                .flat();
              const loadDates = Array.from(new Set(loadStops.map((s) => formatDate(s.date)))).join(', ');
              const unloadDates = Array.from(new Set(unloadStops.map((s) => formatDate(s.date)))).join(', ');
              const loadTimes = Array.from(new Set(loadStops.map((s) => formatInputTime(s.timeStart)))).join(', ');
              const unloadTimes = Array.from(new Set(unloadStops.map((s) => formatInputTime(s.timeEnd)))).join(', ');
              const lineSales = order.lines.map((l) => l.sales).flat();
              const linePurchases = order.lines.map((l) => l.purchases).flat();
              const saleTotal = calculateLinesTotalExclVat(lineSales);
              const purchaseTotal = calculateLinesTotalExclVat(linePurchases);

              return [
                <Link to={`${order.id}/general`} className="link-text whitespace-nowrap">
                  {order.orderNumber ?? 'DRAFT'}
                </Link>,
                order.customer?.name ?? '-',
                order.customerRef,
                [
                  ...new Set(
                    order.lines
                      .map((l) =>
                        l.stops
                          .filter((s) => s.type === OrderLineStopType.Load)
                          .map((s) => s.location.city)
                          .flat(),
                      )
                      .flat(),
                  ),
                ].join(', '),
                [
                  ...new Set(
                    order.lines
                      .map((l) =>
                        l.stops
                          .filter((s) => s.type === OrderLineStopType.Unload)
                          .map((s) => s.location.city)
                          .flat(),
                      )
                      .flat(),
                  ),
                ].join(', '),
                <div className="whitespace-nowrap">{`€ ${formatNumber(purchaseTotal, 2, {
                  decimalSeperator: ',',
                })}`}</div>,
                <div className="whitespace-nowrap">{`€ ${formatNumber(saleTotal, 2, {
                  decimalSeperator: ',',
                })}`}</div>,
                <StatusText color={orderStatusColor}>{orderStatusText}</StatusText>,
                <StatusText color={purchaseStatusColor}>{purchaseStatusText}</StatusText>,
                <StatusText color={saleStatusColor}>{saleStatusText}</StatusText>,
                order.documents.find((d) => d.type === OrderDocumentType.Cmr) ? (
                  <Check className="w-6 h-6 text-feedback-positive" />
                ) : (
                  <X className="w-6 h-6 text-feedback-negative-04" />
                ),
                loadDates,
                loadTimes,
                unloadDates,
                unloadTimes,
                order.supplier?.name ?? '',
              ];
            }}
          />
        </div>

        <div className="my-4">
          <Pagination
            hasPrevious={page.hasPrevious}
            previous={page.previous}
            hasNext={page.hasNext}
            next={page.next}
            isFetching={page.isFetching}
          />
        </div>

        <div className="my-4 px-4">
          <Suspense>
            <OrderCount filters={filters} />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
