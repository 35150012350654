import { Warning, Plus, Trash, ArrowCircleDown, Package, File as FileIcon } from '@phosphor-icons/react';
import { Breadcrumb } from 'components/Breadcrumb';
import { Button } from 'components/button/Button';
import { PageHeader } from 'components/PageHeader';
import { TextAreaField } from 'components/textarea/TextAreaField';
import { FieldArray, FormikConsumer, FormikProvider, useFormik } from 'formik';
import {
  OrderLineStopType,
  TrailerType,
  useCustomerPortalPlaceOrderMutation,
  useGetCustomerPortalOrderQuery,
} from 'generated/graphql';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { InputField } from '../../../../components/input/InputField';
import { getDisplayError } from '../../../../utils/get-display-error';
import {
  IOrderLineStopValues,
  initialValues as initialStopValues,
} from '../../../order/pages/orderLineStop/CreateOrderLineStop';
import {
  IOrderLineContentValues,
  createOrderLineContentSchema,
} from '../../../order/pages/orderLineContent/CreateOrderLineContent';
import { useCustomerPortalCustomer } from '../../CustomerPortalCustomerContext';
import { TrailerTypes } from '../../../order/pages/order/TrailerTypes';
import { STOP_TYPE_ITEMS } from '../../../order/pages/orderLineStop/constants';
import { SuspenseSpinner } from '../../../../components/SuspenseSpinner';
import { CargoTypeComboboxField, cargoTypeToComboboxItem } from '../../../order/cargoType/CargoTypeComboboxField';
import {
  PackageTypeComboboxField,
  packageTypeToComboboxItem,
} from '../../../order/packageType/PackageTypeComboboxField';
import { SimpleSelectField } from '../../../../components/select/SimpleSelectField';
import { CustomerPortalLocationComboboxField } from '../../locations/CustomerPortalLocationCombobox';
import { invariant, nullthrows } from '../../../../utils/invariant';
import { formatNumber, parseNumberInput } from '../../../../utils/number';
import { parseInputTime } from '../../../../utils/date';
import { isTomorrowOrAfter, validateLoadTime } from '../../../order/pages/orderLineStop/utils';
import { useTranslation } from '../../../../contexts/translation-context';
import { PageHeading } from 'components/PageHeading';
import { ArrowCircleUp } from '@phosphor-icons/react/dist/ssr';
import { HorizontalRule } from 'components/HorizontalRule';
import { captureException } from '@sentry/react';
import { IMinimalFile, MultiFileUploaderButton } from 'src/app/document/components/MultiFileUploaderButton';

const createOrderLineStopSchema = Yup.object().shape({
  location: Yup.mixed().required('errors.required'),
  date: Yup.string()
    .required('errors.required')
    .test('isRecent', 'errors.dateShouldBeInTheFuture', function () {
      const value = this.parent.date;
      return isTomorrowOrAfter(value);
    }),
  timeStart: Yup.string()
    .required('errors.required')
    .test('isBefore', 'errors.startTimeShouldBeBeforeEndTime', function () {
      return validateLoadTime(this.parent);
    }),
  timeEnd: Yup.string()
    .required('errors.required')
    .test('isBefore', 'errors.startTimeShouldBeBeforeEndTime', function () {
      return validateLoadTime(this.parent);
    }),
  reference: Yup.string().min(1, 'errors.required').required('errors.required'),
  notes: Yup.string(),
});

const createOrderSchema = Yup.object().shape({
  customerRef: Yup.string(),
  internalNotes: Yup.string(),
  allowedTrailerTypes: Yup.array(),

  stops: Yup.array(createOrderLineStopSchema),
  contents: Yup.array(createOrderLineContentSchema).required('errors.required'),
});

const validateStops = (stops: IOrderLineStopValues[]) => {
  const hasLoad = stops.some((stop) => stop.type?.key === OrderLineStopType.Load);
  const hasUnload = stops.some((stop) => stop.type?.key === OrderLineStopType.Unload);

  return hasLoad && hasUnload;
};

interface IOrderValues {
  customerRef: string;
  internalNotes: string;
  allowedTrailerTypes: TrailerType[];
  stops: IOrderLineStopValues[];
  contents: IOrderLineContentValues[];
}

export const CustomerPortalPlaceOrderPage = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { customer } = useCustomerPortalCustomer();
  const [, placeOrder] = useCustomerPortalPlaceOrderMutation();
  const [attachments, setAttachments] = useState<IMinimalFile[]>([]);

  const [searchParams] = useSearchParams();
  const copyFrom = searchParams.get('copy-from');
  const [{ data }] = useGetCustomerPortalOrderQuery({
    variables: {
      id: copyFrom ?? '',
    },
    pause: !copyFrom,
  });

  const getDefaultContentLine = () => {
    return {
      cargoType: customer.defaultCargoType ? cargoTypeToComboboxItem(customer.defaultCargoType) : null,
      packages: '33',
      packageType: customer.defaultPackageType ? packageTypeToComboboxItem(customer.defaultPackageType) : null,
      weight: '24000',
      volume: '90',
      loadingMeters: '13.60',
    };
  };

  const existingOrder = data?.customerPortalOrder;
  const initialValues: IOrderValues = useMemo(() => {
    if (existingOrder && existingOrder.lines.length > 0) {
      const line = existingOrder.lines[0]!;
      return {
        customerRef: '',
        internalNotes: '',
        allowedTrailerTypes: existingOrder.allowedTrailerTypes,
        stops: line.stops.map((stop) => ({
          type: STOP_TYPE_ITEMS.find((v) => v.key === stop.type)!,
          location: stop.location,
          date: '',
          timeStart: '',
          timeEnd: '',
          reference: '',
          notes: stop.notes,
        })),
        contents: line.contents.map((content) => ({
          cargoType: cargoTypeToComboboxItem(content.cargoType),
          packages: formatNumber(content.packages, 2),
          packageType: packageTypeToComboboxItem(content.packageType),
          weight: formatNumber(content.weight, 2),
          volume: formatNumber(content.volume, 2),
          loadingMeters: formatNumber(content.loadingMeters, 2),
        })),
      };
    }

    return {
      customerRef: '',
      internalNotes: '',
      allowedTrailerTypes: customer.defaultTrailerTypes,
      stops: [
        {
          ...initialStopValues,
          type: STOP_TYPE_ITEMS.find((item) => item.key === OrderLineStopType.Load)!,
        },
        {
          ...initialStopValues,
          type: STOP_TYPE_ITEMS.find((item) => item.key === OrderLineStopType.Unload)!,
        },
      ],
      contents: [getDefaultContentLine()],
    };
  }, []);

  const formikbag = useFormik({
    initialValues: initialValues,
    validationSchema: createOrderSchema,
    onSubmit: async (values) => {
      try {
        const {
          customerRef,
          internalNotes,

          stops,
          contents,
          allowedTrailerTypes,
        } = values;

        if (!stops.length) {
          toast.error(i18n('customerPortal.orders.stopsRequired'));
          return;
        }

        if (!contents.length) {
          toast.error(i18n('customerPortal.orders.contentRequired'));
          return;
        }

        const result = await placeOrder({
          customerId: customer.id,
          data: {
            customerRef,
            internalNotes,
            allowedTrailerTypes,
            lines: [
              {
                stops: stops.map((stop, i) => {
                  const { type: selectedType, location, date, timeStart, timeEnd, reference, notes } = stop;
                  const locationId = location?.id;
                  invariant(locationId);
                  const type = nullthrows(selectedType?.key, 'Stop type is required');

                  return {
                    sequenceIndex: i * 10,
                    type: type as OrderLineStopType,
                    locationId,
                    date,
                    timeStart: parseInputTime(timeStart),
                    timeEnd: parseInputTime(timeEnd),
                    reference,
                    notes,
                  };
                }),

                contents: contents.map((content) => {
                  const { cargoType, packageType, packages, weight, volume, loadingMeters } = content;
                  const cargoTypeId = cargoType?.key;
                  const packageTypeId = packageType?.key;
                  invariant(cargoTypeId);
                  invariant(packageTypeId);

                  return {
                    cargoTypeId,
                    packageTypeId,
                    packages: parseNumberInput(packages, 2),
                    weight: parseNumberInput(weight, 2),
                    volume: parseNumberInput(volume, 2),
                    loadingMeters: parseNumberInput(loadingMeters, 2),
                  };
                }),
              },
            ],
            attachmentIds: attachments.map((v) => v.id),
          },
        });
        if (result.error) {
          throw result.error;
        }
        if (result.data) {
          navigate(`../${result.data.customerPortalPlaceOrder.id}`);
        }
        toast.success('Order geplaatst');
      } catch (err: any) {
        captureException(err);
        toast.error('Kon order niet plaatsen: ' + getDisplayError(err));
      }
    },
  });

  const setFiles = async (files: IMinimalFile[]) => {
    setAttachments(
      files.map((v) => {
        return {
          id: v.id,
          hash: v.hash,
          name: v.name,
        };
      }),
    );
  };

  const isValidStops = useMemo(() => {
    return validateStops(formikbag.values.stops);
  }, [formikbag.values.stops]);
  const title = i18n('customerPortal.orders.placeOrder');
  return (
    <>
      <PageHeader title={title} />

      <>
        <PageHeading
          leftSide={
            <Breadcrumb
              parentItem={{
                name: i18n('customerPortal.orders.orders'),
                to: '..',
              }}
              currentItem={title}
            />
          }
        />

        <div className="px-4">
          <FormikProvider value={formikbag}>
            <FormikConsumer>
              {({ handleSubmit, isSubmitting, values: _values, errors, setFieldValue }) => {
                const values = _values as IOrderValues;

                console.log(errors.stops);

                return (
                  <form onSubmit={handleSubmit}>
                    <h2 className="section-header mb-4">{i18n('customerPortal.orders.invoiceRef')}</h2>

                    <div className="card p-4 mb-8">
                      <InputField
                        type="text"
                        labelText={i18n('customerPortal.orders.invoiceRef')}
                        name="customerRef"
                        isDisabled={isSubmitting}
                      />
                    </div>

                    <div className="my-8">
                      <HorizontalRule />
                    </div>

                    <h2 className="section-header mb-4">{i18n('customerPortal.orders.assignment')}</h2>

                    <FieldArray
                      name="stops"
                      render={(arrayHelpers) => (
                        <div className="my-4">
                          <div className="flex justify-between items-end mb-4">
                            <h2 className="heading-two">{i18n('customerPortal.orders.stops')}</h2>
                            <Button
                              onTrigger={() => {
                                const previousStop = values['stops'][values['stops'].length - 1];
                                const itemToAdd = { ...initialStopValues };
                                if (previousStop) {
                                  if (values.stops.length === 1) {
                                    itemToAdd.type = STOP_TYPE_ITEMS.find((v) => v.key === OrderLineStopType.Unload)!;
                                  }
                                  // itemToAdd.date = previousStop.date;
                                }
                                arrayHelpers.push(itemToAdd);
                              }}
                              iconLeft={<Plus className="button-icon" />}
                            >
                              {i18n('customerPortal.orders.addStop')}
                            </Button>
                          </div>

                          {values['stops'].length > 0 ? (
                            <div>
                              <div className="grid grid-cols-2 gap-4">
                                {values['stops'].map((stop, index) => {
                                  return (
                                    <div className="card p-4" key={`order-stop-${index}`}>
                                      <div className="flex justify-between items-center mb-4">
                                        <div className="flex gap-2 items-center">
                                          {stop.type?.key === OrderLineStopType.Load ? (
                                            <ArrowCircleUp className="w-6 h-6 text-orange-00" />
                                          ) : (
                                            <ArrowCircleDown className="w-6 h-6 text-orange-00" />
                                          )}
                                          <div className="heading-three">{`Stop ${index + 1} - ${stop.type?.name ?? STOP_TYPE_ITEMS[0]!.name}`}</div>
                                        </div>

                                        <Button
                                          color="danger-outline"
                                          onTrigger={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                        >
                                          <div className="flex items-center gap-2">
                                            <Trash className="button-icon" />
                                            {i18n('customerPortal.orders.deleteStop')}
                                          </div>
                                        </Button>
                                      </div>

                                      <div className="flex gap-4 items-center">
                                        <div className="flex-1">
                                          <SimpleSelectField
                                            labelText="Type stop"
                                            items={STOP_TYPE_ITEMS}
                                            name={`stops[${index}].type`}
                                          />
                                        </div>
                                      </div>
                                      <CustomerPortalLocationComboboxField
                                        labelText={i18n('customerPortal.orders.location')}
                                        name={`stops[${index}].location`}
                                        isDisabled={isSubmitting}
                                      />
                                      <div className="flex gap-2">
                                        <div className="flex-1">
                                          <InputField
                                            labelText={i18n('customerPortal.orders.date')}
                                            type="date"
                                            name={`stops[${index}].date`}
                                            isDisabled={isSubmitting}
                                          />
                                        </div>
                                        <div className="flex gap-2 flex-1">
                                          <InputField
                                            labelText={i18n('customerPortal.orders.start')}
                                            type="time"
                                            name={`stops[${index}].timeStart`}
                                            isDisabled={isSubmitting}
                                          />
                                          <InputField
                                            labelText={i18n('customerPortal.orders.stop')}
                                            type="time"
                                            name={`stops[${index}].timeEnd`}
                                            isDisabled={isSubmitting}
                                          />
                                        </div>
                                      </div>
                                      <InputField
                                        labelText={i18n('customerPortal.orders.reference')}
                                        type="text"
                                        name={`stops[${index}].reference`}
                                        isDisabled={isSubmitting}
                                      />
                                      <TextAreaField
                                        labelText={i18n('customerPortal.orders.extraNotes')}
                                        name={`stops[${index}].notes`}
                                        isDisabled={isSubmitting}
                                        spellCheck={true}
                                      />
                                    </div>
                                  );
                                })}
                              </div>

                              {!isValidStops && (
                                <div className="font-medium text-feedback-negative-04 flex gap-2 items-center">
                                  <Warning className="h-4 w-4" />
                                  {i18n('customerPortal.orders.noStopsError')}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="font-medium text-feedback-negative-04 flex gap-2 items-center">
                              <Warning className="h-4 w-4" />
                              {i18n('customerPortal.orders.noStopsError')}
                            </div>
                          )}
                        </div>
                      )}
                    />

                    <div className="my-8">
                      <HorizontalRule />
                    </div>

                    <FieldArray
                      name="contents"
                      render={(arrayHelpers) => (
                        <div className="my-4">
                          <div className="flex justify-between items-end mb-4">
                            <h2 className="heading-two">{i18n('customerPortal.orders.content')}</h2>
                            <Button
                              onTrigger={() => {
                                arrayHelpers.push(getDefaultContentLine());
                              }}
                              iconLeft={<Plus className="button-icon" />}
                            >
                              {i18n('customerPortal.orders.addContent')}
                            </Button>
                          </div>

                          {values['contents'].length > 0 ? (
                            <div>
                              {values['contents'].map((_content, index) => {
                                return (
                                  <SuspenseSpinner key={`order-content-${index}`}>
                                    <div className="card p-4">
                                      <div className="flex justify-between items-center mb-4">
                                        <div className="flex gap-2 items-center">
                                          <Package className="w-6 h-6 text-orange-00" />
                                          <div className="heading-three">{`${i18n('customerPortal.orders.content')} ${index + 1}`}</div>
                                        </div>

                                        <Button
                                          color="danger-outline"
                                          onTrigger={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                        >
                                          <div className="flex items-center gap-2">
                                            <Trash className="button-icon" />
                                            {i18n('customerPortal.orders.deleteContent')}
                                          </div>
                                        </Button>
                                      </div>

                                      <div className="flex items-center gap-4">
                                        <div className="grid grid-cols-3 gap-2 flex-1">
                                          <CargoTypeComboboxField
                                            labelText={i18n('customerPortal.orders.content')}
                                            name={`contents[${index}].cargoType`}
                                            onlySelect={true}
                                          />
                                          <InputField
                                            labelText={i18n('customerPortal.orders.coli')}
                                            type="number"
                                            name={`contents[${index}].packages`}
                                            step="0.01"
                                          />
                                          <PackageTypeComboboxField
                                            labelText={i18n('customerPortal.orders.packaging')}
                                            name={`contents[${index}].packageType`}
                                            onlySelect={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="flex gap-2">
                                        <InputField
                                          labelText={i18n('customerPortal.orders.weight')}
                                          type="number"
                                          name={`contents[${index}].weight`}
                                          step="0.01"
                                        />
                                        <InputField
                                          labelText={i18n('customerPortal.orders.volume')}
                                          type="number"
                                          name={`contents[${index}].volume`}
                                          step="0.01"
                                        />
                                        <InputField
                                          labelText={i18n('customerPortal.orders.loadMeter')}
                                          type="number"
                                          name={`contents[${index}].loadingMeters`}
                                          step="0.01"
                                        />
                                      </div>
                                    </div>
                                  </SuspenseSpinner>
                                );
                              })}
                            </div>
                          ) : (
                            <div>{i18n('customerPortal.orders.noContent')}</div>
                          )}
                        </div>
                      )}
                    />

                    <div className="my-8">
                      <HorizontalRule />
                    </div>

                    <h2 className="heading-two mb-4">{i18n('customerPortal.orders.trailerTypes')}</h2>

                    <div className="card p-4">
                      <TrailerTypes
                        value={values.allowedTrailerTypes}
                        onChange={(newTrailerTypes) => {
                          setFieldValue('allowedTrailerTypes', newTrailerTypes);
                        }}
                        isMultiSelect={true}
                      />
                    </div>

                    <div className="my-8">
                      <HorizontalRule />
                    </div>

                    <h2 className="heading-two mb-4">{i18n('customerPortal.orders.extraNotes')}</h2>

                    <div className="card p-4 mb-4">
                      <TextAreaField
                        labelText={i18n('customerPortal.orders.extraNotes')}
                        name="internalNotes"
                        isDisabled={isSubmitting}
                        spellCheck={true}
                      />
                    </div>

                    <h2 className="heading-two mb-4">{i18n('customerPortal.orders.attachments')}</h2>

                    <div className="card p-4 my-4">
                      <MultiFileUploaderButton
                        buttonText={i18n('customerPortal.orders.uploadAttachments')}
                        title={i18n('customerPortal.orders.uploadAttachments')}
                        files={attachments}
                        onSubmit={setFiles}
                        unlinkDocument={async (id) => {
                          setAttachments((prev) => prev.filter((v) => v.id !== id));
                        }}
                      />
                    </div>

                    <div className="mt-8">
                      <Button
                        type="submit"
                        color="primary"
                        isDisabled={isSubmitting}
                        isLoading={isSubmitting}
                        iconLeft={<Plus className="button-icon" />}
                      >
                        {i18n('customerPortal.orders.placeOrder')}
                      </Button>
                    </div>
                  </form>
                );
              }}
            </FormikConsumer>
          </FormikProvider>
        </div>
      </>
    </>
  );
};
