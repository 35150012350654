import { Suspense, useEffect, useMemo, useState } from 'react';

import { PageHeader } from '../../../components/PageHeader';
import { Spinner } from '../../../components/Spinner';
import { TimeFrame } from '../../../components/time-frame/time-frame';
import { TimeFrameState } from '../../../components/time-frame/types';
import { BusinessUnit, useOrderStatsQuery, UserRole } from '../../../generated/graphql';
import { DashboardProvider } from '../context';
import { Tabs } from '../../../components/tabs/Tabs';
import { PageHeading } from 'components/PageHeading';
import { Breadcrumb } from 'components/Breadcrumb';
import { SimpleSelect } from 'components/select/SimpleSelect';
import { BUSINESS_UNIT_OPTIONS } from 'src/app/order/constants';
import { InternalUser, UserCombobox } from 'src/app/user/components/UserCombobox';
import { useAuth } from 'contexts/auth-context';
import { roleToNumber } from 'src/app/auth/roles.client';

interface StatsProps {
  dealOwnerId: string | null;
  businessUnit: BusinessUnit | null;
  timeFrame: TimeFrameState;
  onFetchingChange: (fetching: boolean) => void;
}

const Statistics: React.FC<StatsProps> = (props) => {
  const { dealOwnerId, businessUnit, timeFrame, onFetchingChange } = props;
  const { me } = useAuth();
  const [{ data, fetching }, refetch] = useOrderStatsQuery({
    variables: {
      filters: {
        dealOwnerId,
        businessUnit,
        startDate: timeFrame.startDate,
        endDate: timeFrame.endDate,
      },
    },
  });

  useEffect(() => {
    onFetchingChange(fetching);
  }, [fetching]);

  useEffect(() => {
    refetch({
      requestPolicy: 'network-only',
    });
  }, [timeFrame.startDate, timeFrame.endDate]);

  const meRoleNumber = roleToNumber(me.role);
  const adminRoleNumber = roleToNumber(UserRole.Admin);

  const tabs = useMemo(() => {
    const _tabs = [];
    if (meRoleNumber >= adminRoleNumber) {
      _tabs.push(
        {
          title: 'Overzicht',
          path: 'overview',
        },
        {
          title: 'Boekhouding',
          path: 'accounting',
        },
      );
    }

    _tabs.push({
      title: 'Sales',
      path: 'sales',
    });

    return _tabs;
  }, [meRoleNumber]);

  if (!data) {
    return null;
  }

  const entries = data.orderStats;
  return (
    <DashboardProvider entries={entries} refreshData={refetch}>
      <Tabs items={tabs} />
    </DashboardProvider>
  );
};

export const StatisticsPage = () => {
  const { me } = useAuth();

  const [dealOwnerValue, setDealOwnerValue] = useState<InternalUser | null>(null);
  const [dealOwnerId, setDealOwnerId] = useState<null | string>(null);
  const [businessUnit, setBusinessUnit] = useState<null | BusinessUnit>(null);
  const [timeFrame, setTimeFrame] = useState<null | TimeFrameState>(null);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const meRoleNumber = roleToNumber(me.role);
  const adminRoleNumber = roleToNumber(UserRole.Admin);

  return (
    <>
      <PageHeader title="Statistieken" />

      <div>
        <PageHeading leftSide={<Breadcrumb currentItem="Statistieken" />} />

        <div className="px-4">
          <div className="flex flex-wrap items-end mb-16 gap-4">
            <div>{isFetching && <Spinner size={8} />}</div>

            <div>
              <TimeFrame onChange={setTimeFrame} />
            </div>

            <div className="w-full max-w-64">
              <label>
                <div className="label-text">Business Unit</div>
                <SimpleSelect
                  onSelect={(value) => {
                    if (!value) {
                      setBusinessUnit(null);
                    } else {
                      setBusinessUnit(value.key as BusinessUnit);
                    }
                  }}
                  selectedItem={BUSINESS_UNIT_OPTIONS.find((v) => v.key === businessUnit) ?? null}
                  items={BUSINESS_UNIT_OPTIONS}
                  isOptional={true}
                />
              </label>
            </div>

            {meRoleNumber >= adminRoleNumber && (
              <label>
                <div className="label-text">Dealeigenaar</div>
                <UserCombobox
                  value={dealOwnerValue}
                  onChange={(v) => {
                    setDealOwnerValue(v);
                    setDealOwnerId(v?.id ?? null);
                  }}
                />
              </label>
            )}
          </div>

          <Suspense
            fallback={
              <div className="flex justify-center items-center mt-32">
                <Spinner size={8} />
              </div>
            }
          >
            {timeFrame && (
              <Statistics
                timeFrame={timeFrame}
                businessUnit={businessUnit}
                dealOwnerId={dealOwnerId}
                onFetchingChange={setIsFetching}
              />
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};
