import { useDashboardEntries } from '../context';
import { formatNumber } from '@utils/number';
import { ITableHeader, Table } from 'components/table/Table';

const TABLE_HEADERS: ITableHeader[] = [
  {
    id: 'customerName',
    name: 'Naam klant',
  },
  {
    id: 'orderCount',
    name: 'Aantal Orders',
  },
  {
    id: 'saleTotal',
    name: 'Verkoop totaal',
  },
  {
    id: 'purchaseTotal',
    name: 'Aankoop totaal',
  },
  {
    id: 'margin',
    name: 'Marge',
  },
];

export const SalesStats: React.FC = () => {
  const { entries } = useDashboardEntries();

  const groupedByCustomer: Map<
    number,
    {
      customerId: number;
      customerName: string;
      count: number;
      saleAmount: number;
      purchaseAmount: number;
    }
  > = new Map();

  for (const entry of entries) {
    if (entry.customerId == null) continue;

    const existing = groupedByCustomer.get(entry.customerId);
    if (existing) {
      existing.count++;
      existing.saleAmount += entry.saleAmount;
      existing.purchaseAmount += entry.purchaseAmount;
    } else {
      groupedByCustomer.set(entry.customerId, {
        customerId: entry.customerId,
        customerName: entry.customerName ?? '',
        count: 1,
        saleAmount: entry.saleAmount,
        purchaseAmount: entry.purchaseAmount,
      });
    }
  }

  const tableEntries = [
    ...groupedByCustomer.entries().map(([customerId, { customerName, count, saleAmount, purchaseAmount }]) => {
      return {
        id: customerId,
        customerName,
        orderCount: count,
        saleTotal: saleAmount,
        purchaseTotal: purchaseAmount,
        margin: saleAmount - purchaseAmount,
      };
    }),
  ];

  return (
    <div>
      <Table
        idKey="id"
        headers={TABLE_HEADERS}
        data={tableEntries}
        mapData={(entry) => {
          return [
            entry.customerName,
            entry.orderCount,
            `€ ${formatNumber(entry.saleTotal, 2, {
              decimalSeperator: ',',
            })}`,
            `€ ${formatNumber(entry.purchaseTotal, 2, {
              decimalSeperator: ',',
            })}`,
            `€ ${formatNumber(entry.margin, 2, {
              decimalSeperator: ',',
            })}`,
          ];
        }}
      />
    </div>
  );
};
