import { Navigate } from 'react-router-dom';

import { useDashboardEntries } from '../context';
import { ChartWithTable } from '../../../components/graphs/chart-with-table';
import { roleToNumber } from 'src/app/auth/roles.client';
import { UserRole } from 'generated/graphql';
import { useAuth } from 'contexts/auth-context';

function calculateGrossMarginPercentage(saleAmount: number, purchaseAmount: number): number {
  if (saleAmount === 0) {
    return 0;
  }

  return (saleAmount - purchaseAmount) / saleAmount;
}

export const OrdersOverviewStats: React.FC = () => {
  const { entries } = useDashboardEntries();
  const { me } = useAuth();

  // const entries = _entries.filter((v) => !v.noPurchase);

  const groupedByCustomer: Map<
    number,
    {
      customerId: number;
      customerName: string;
      count: number;
      saleAmount: number;
      purchaseAmount: number;
    }
  > = new Map();

  for (const entry of entries) {
    if (entry.customerId == null) continue;

    const existing = groupedByCustomer.get(entry.customerId);
    if (existing) {
      existing.count++;
      existing.saleAmount += entry.saleAmount;
      existing.purchaseAmount += entry.purchaseAmount;
    } else {
      groupedByCustomer.set(entry.customerId, {
        customerId: entry.customerId,
        customerName: entry.customerName ?? '',
        count: 1,
        saleAmount: entry.saleAmount,
        purchaseAmount: entry.purchaseAmount,
      });
    }
  }

  const groupedByOrigin: Map<string, number> = new Map();
  for (const entry of entries) {
    groupedByOrigin.set(entry.origin, (groupedByOrigin.get(entry.origin) ?? 0) + 1);
  }

  const groupedByCustomerType: Map<string, number> = new Map();
  for (const entry of entries) {
    if (!entry.customerType) {
      continue;
    }

    groupedByCustomerType.set(entry.customerType, (groupedByCustomerType.get(entry.customerType) ?? 0) + 1);
  }

  const totalSaleValue = entries.reduce((acc, v) => acc + v.saleAmount, 0);
  const totalPurchaseValue = entries.reduce((acc, v) => acc + v.purchaseAmount, 0);
  const totalMarginPercentage = calculateGrossMarginPercentage(totalSaleValue, totalPurchaseValue) * 100;
  const totalOrderAverage = totalSaleValue / entries.length;
  const averageOrderGrossMargin = (totalSaleValue - totalPurchaseValue) / entries.length;

  const aggregatedValues = Array.from(groupedByCustomer.values());
  const revenueStats = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: s.saleAmount / 100,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const averageOrderValue = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: s.saleAmount / s.count / 100,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const marginStats = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: (s.saleAmount - s.purchaseAmount) / 100,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const averageGrossMarginValue = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      const averageSaleAmount = s.saleAmount / s.count;
      const averagePurchaseAmount = s.purchaseAmount / s.count;

      return {
        id: s.customerId,
        name: s.customerName,
        value: (averageSaleAmount - averagePurchaseAmount) / 100,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const marginPercentageStats = aggregatedValues
    .sort((a, b) => b.saleAmount - a.saleAmount)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: calculateGrossMarginPercentage(s.saleAmount, s.purchaseAmount) * 100,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const countByCustomerStats = aggregatedValues
    .sort((a, b) => b.count - a.count)
    .map((s) => {
      return {
        id: s.customerId,
        name: s.customerName,
        value: s.count,
        link: `/internal/customers/${s.customerId}/general`,
      };
    });
  const countByOriginStats = Array.from(groupedByOrigin.entries())
    .sort((a, b) => b[1] - a[1])
    .map((s, idx) => {
      return {
        id: s[0],
        name: s[0],
        value: s[1],
      };
    });
  const countByCustomerType = Array.from(groupedByCustomerType.entries())
    .sort((a, b) => b[1] - a[1])
    .map((s, idx) => {
      return {
        id: s[0],
        name: s[0],
        value: s[1],
      };
    });

  const spotOrderCount = entries.filter((e) => e.supplierId && !e.regularCarrier).length;
  const regularCarrierOrderCount = entries.filter((e) => e.supplierId && !!e.regularCarrier).length;
  const orderCountPerType = [
    {
      id: 1,
      name: 'Spot Orders',
      value: spotOrderCount,
    },
    {
      id: 2,
      name: 'Vaste vervoerder Orders',
      value: regularCarrierOrderCount,
    },
  ];

  const meRoleNumber = roleToNumber(me.role);
  const adminRoleNumber = roleToNumber(UserRole.Admin);

  if (meRoleNumber < adminRoleNumber) {
    return <Navigate to="/internal/stats/sales" />;
  }

  return (
    <div>
      <div className="heading-two mb-4">Overzicht Orders</div>
      <div className="2xl:grid grid-cols-2 gap-x-8">
        <div className="mb-4">
          <ChartWithTable
            title="Omzet"
            nameTitle="Klant"
            data={revenueStats}
            dataType="currency"
            showPercentage={true}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Gemiddelde Orderwaarde"
            nameTitle="Klant"
            data={averageOrderValue}
            dataType="currency"
            showPercentage={true}
            totalAverage={totalOrderAverage / 100}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Bruto Marge"
            nameTitle="Klant"
            data={marginStats}
            dataType="currency"
            showPercentage={true}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Gemiddelde Bruto Marge Per Order"
            nameTitle="Klant"
            data={averageGrossMarginValue}
            dataType="currency"
            showPercentage={true}
            totalAverage={averageOrderGrossMargin / 100}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Bruto Marge Percentage"
            nameTitle="Klant"
            data={marginPercentageStats}
            dataType="percentage"
            showPercentage={true}
            totalAverage={totalMarginPercentage}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per klant"
            nameTitle="Klant"
            data={countByCustomerStats}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per klant type"
            nameTitle="Klant"
            data={countByCustomerType}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Aantal Orders per entry type"
            nameTitle="Entry type"
            data={countByOriginStats}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>

        <div className="mb-4">
          <ChartWithTable
            title="Verhouding Spot Orders"
            nameTitle="Type"
            data={orderCountPerType}
            dataType="value"
            showPercentage={true}
            precision={0}
          />
        </div>
      </div>
    </div>
  );
};
