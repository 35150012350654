import { Breadcrumb } from 'components/Breadcrumb';
import { PageHeader } from 'components/PageHeader';
import { ITabItem, Tabs } from 'components/tabs/Tabs';
import {
  TrailerDocumentType,
  TrailerQuery,
  useLinkTrailerDocumentMutation,
  UserRole,
  useTrailerQuery,
  useUnlinkTrailerDocumentMutation,
  useUpdateTrailerMutation,
} from 'generated/graphql';
import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useParams, useResolvedPath } from 'react-router-dom';

import { PageHeading } from 'components/PageHeading';
import { useAuth } from 'contexts/auth-context';
import { invariant } from '@utils/invariant';
import { ConfirmDialog } from 'components/dialog/ConfirmDialog';
import { getDisplayError } from '@utils/get-display-error';
import { StatusBadge } from 'components/StatusBadge';
import { TrailerProvider } from './trailerContext';
import { MultiFileUploaderButton } from 'src/app/document/components/MultiFileUploaderButton';
import { captureException } from '@sentry/react';
import { roleToNumber } from 'src/app/auth/roles.client';

export type Trailer = NonNullable<TrailerQuery['trailer']>;

export const TrailerPage = () => {
  const { me } = useAuth();
  const generalRoute = useResolvedPath('general');
  const costsRoute = useResolvedPath('costs');
  const auditLogsRoute = useResolvedPath('audit-logs');
  const tabItems = useMemo<ITabItem[]>(() => {
    const res = [
      {
        title: 'Algemeen',
        path: generalRoute.pathname,
      },
      {
        title: 'Kosten',
        path: costsRoute.pathname,
      },
    ];

    if (roleToNumber(me.role) >= roleToNumber(UserRole.Admin)) {
      res.push({
        title: 'Audit logs',
        path: auditLogsRoute.pathname,
      });
    }

    return res;
  }, []);

  const { trailerId } = useParams<{ trailerId: string }>();
  invariant(trailerId);
  const [{ data, error }, refetch] = useTrailerQuery({
    variables: {
      id: +trailerId,
    },
  });
  const [, updateTrailer] = useUpdateTrailerMutation();
  const [, linkDocument] = useLinkTrailerDocumentMutation();
  const [, unlinkDocument] = useUnlinkTrailerDocumentMutation();

  const refreshData = useCallback(() => {
    return refetch({
      requestPolicy: 'network-only',
    });
  }, [refetch]);

  const trailer = data?.trailer;
  if (!trailer) {
    if (error) {
      return <div>Kon trailer niet laden: {error.message}</div>;
    } else {
      return <div>Trailer niet gevonden</div>;
    }
  } else {
    return (
      <>
        <PageHeader title={`Trailer ${trailer.licensePlate}`} />

        <TrailerProvider trailer={trailer} refreshData={refreshData}>
          <PageHeading
            leftSide={
              <div className="flex items-center gap-2">
                <Breadcrumb
                  parentItem={{
                    name: 'Trailers',
                    to: '/internal/trailers',
                  }}
                  currentItem={trailer.licensePlate}
                />
                {!trailer.isActive && <StatusBadge color="red">Inactief</StatusBadge>}
              </div>
            }
            rightSide={
              <div className="flex gap-2">
                <ConfirmDialog
                  triggerText={trailer.isActive ? 'Deactiveer trailer' : 'Heractiveer trailer'}
                  title={trailer.isActive ? 'Deactiveer trailer' : 'Heractiveer trailer'}
                  submitText={trailer.isActive ? 'Deactiveer trailer' : 'Heractiveer trailer'}
                  description="Ben je zeker?"
                  onSubmit={async () => {
                    try {
                      const result = await updateTrailer({
                        id: trailer.id,
                        data: {
                          isActive: !trailer.isActive,
                        },
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      toast.success(`Trailer is ${trailer.isActive ? 'gedeactiveerd' : 'heractiveerd'}`);
                    } catch (err) {
                      captureException(err);
                      toast.error('Mislukt: ' + getDisplayError(err));
                    }
                  }}
                />
              </div>
            }
          />

          <div className="flex px-4 mb-4 justify-end gap-4">
            <MultiFileUploaderButton
              buttonText="COC"
              title="Gelijkvormigheidsattest (COC)"
              files={trailer.documents.filter((d) => d.documentType === TrailerDocumentType.Coc).map((f) => f.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        trailerId: trailer.id,
                        documentId: doc.id,
                        documentType: TrailerDocumentType.Coc,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const result = await unlinkDocument({
                    trailerId: trailer.id,
                    documentId: docId,
                    documentType: TrailerDocumentType.Coc,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  captureException(err);
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />
            <MultiFileUploaderButton
              buttonText="Identificatie"
              title="Identificatieverslag"
              files={trailer.documents
                .filter((d) => d.documentType === TrailerDocumentType.Identification)
                .map((f) => f.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        trailerId: trailer.id,
                        documentId: doc.id,
                        documentType: TrailerDocumentType.Identification,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const result = await unlinkDocument({
                    trailerId: trailer.id,
                    documentId: docId,
                    documentType: TrailerDocumentType.Identification,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  captureException(err);
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />
            <MultiFileUploaderButton
              buttonText="Kenteken"
              title="Kentekenbewijs"
              files={trailer.documents
                .filter((d) => d.documentType === TrailerDocumentType.Registration)
                .map((f) => f.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        trailerId: trailer.id,
                        documentId: doc.id,
                        documentType: TrailerDocumentType.Registration,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const result = await unlinkDocument({
                    trailerId: trailer.id,
                    documentId: docId,
                    documentType: TrailerDocumentType.Registration,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  captureException(err);
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />
            <MultiFileUploaderButton
              buttonText="Keuring"
              title="Keuringsbewijs"
              files={trailer.documents
                .filter((d) => d.documentType === TrailerDocumentType.Inspection)
                .map((f) => f.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        trailerId: trailer.id,
                        documentId: doc.id,
                        documentType: TrailerDocumentType.Inspection,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const result = await unlinkDocument({
                    trailerId: trailer.id,
                    documentId: docId,
                    documentType: TrailerDocumentType.Inspection,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  captureException(err);
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />
            <MultiFileUploaderButton
              buttonText="Verzekering"
              title="Verzekeringsbewijs"
              files={trailer.documents
                .filter((d) => d.documentType === TrailerDocumentType.Insurance)
                .map((f) => f.document)}
              onSubmit={async (documents) => {
                let count = 0;
                await Promise.allSettled(
                  documents.map(async (doc) => {
                    try {
                      const result = await linkDocument({
                        trailerId: trailer.id,
                        documentId: doc.id,
                        documentType: TrailerDocumentType.Insurance,
                      });
                      if (result.error) {
                        throw result.error;
                      }
                      count += 1;
                      toast.success(`Bestand: ${doc.name}, ${count}/${documents.length} opgeladen`);
                    } catch (err) {
                      captureException(err);
                      toast.error(`Kon bestand ${doc.name} niet opladen: ${getDisplayError(err)}`);
                    }
                  }),
                );
              }}
              unlinkDocument={async (docId: string) => {
                try {
                  const result = await unlinkDocument({
                    trailerId: trailer.id,
                    documentId: docId,
                    documentType: TrailerDocumentType.Insurance,
                  });
                  if (result.error) {
                    throw result.error;
                  }
                  toast.success('Bestand gewist');
                } catch (err) {
                  captureException(err);
                  toast.error(`Kon bestand niet wissen: ${getDisplayError(err)}`);
                }
              }}
            />
          </div>

          <div className="px-4">
            <Tabs items={tabItems} />
          </div>
        </TrailerProvider>
      </>
    );
  }
};
