import { UserRole } from 'generated/graphql';

export function roleToNumber(role: UserRole | 'Anonymous'): number {
  switch (role) {
    case UserRole.AdminPlus: {
      return 20;
    }
    case UserRole.Admin: {
      return 10;
    }
    case UserRole.EmployeePlus: {
      return 5;
    }
    case UserRole.EmployeePlanning: {
      return 1;
    }
    case 'Anonymous': {
      return 0;
    }
  }
}
