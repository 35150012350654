import { Plus } from '@phosphor-icons/react';
import { Formik } from 'formik';
import { Document } from '@prisma/client';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { Button } from '../../../components/button/Button';
import { Dropzone } from '../../../components/Dropzone';
import { getDisplayError } from '../../../utils/get-display-error';
import { captureException } from '@sentry/react';
import { useTranslation } from 'contexts/translation-context';

const createDocumentSchema = Yup.object().shape({
  files: Yup.array(Yup.mixed()).required('Vereist'),
});

export interface IUploadFormProps {
  onSubmit: (values: Document[]) => Promise<void>;
  onCancel: () => void;
}

export const MultiUploadForm: React.FC<IUploadFormProps> = (props) => {
  const { onSubmit, onCancel } = props;
  const { i18n } = useTranslation();

  return (
    <Formik
      initialValues={{
        files: [],
      }}
      validationSchema={createDocumentSchema}
      onSubmit={async (values) => {
        try {
          if (!values.files.length) {
            throw new Error(i18n('upload.noDocumentsSelected'));
          }

          // eslint-disable-next-line no-restricted-globals
          const files: File[] = values.files;
          const results = await Promise.allSettled(
            files.map(async (file) => {
              const formData = new FormData();
              formData.set('name', file.name);
              formData.set('file', file);

              const result = await fetch('/api/document/upload', {
                body: formData,
                method: 'post',
              });

              const text = await result.text();
              if (!result.ok) {
                console.error(text);
                throw new Error(i18n('upload.uploadFailed'));
              }

              return JSON.parse(text).data;
            }),
          );

          results.forEach((result, idx) => {
            if (result.status === 'rejected') {
              const file = files[idx];
              toast.error(
                i18n('upload.uploadDocumentFailed', {
                  filename: file.name,
                  reason: getDisplayError(result.reason),
                }),
              );
            }
          });

          const succeeded = results.map((v) => v.status === 'fulfilled' && v.value).filter(Boolean);
          await onSubmit(succeeded);
        } catch (err: any) {
          captureException(err);
          toast.error(
            i18n('upload.multiUploadFailed', {
              reason: getDisplayError(err),
            }),
          );
        }
      }}
    >
      {({ handleSubmit, isSubmitting, getFieldHelpers, values, errors }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className="mb-8">
              <Dropzone
                files={values['files']}
                allowMultiple={true}
                onUpload={(files) => {
                  getFieldHelpers('files').setValue(files);
                }}
                accept="application/pdf"
              />
            </div>

            <div className="flex justify-between">
              <Button onTrigger={onCancel}>{i18n('cancel')}</Button>

              <Button
                type="submit"
                color="primary"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                iconLeft={<Plus className="button-icon" />}
              >
                {i18n('upload.uploadDocuments')}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
