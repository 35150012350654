import { ErrorBoundary, FallbackProps } from 'react-error-boundary';

import { Button } from './button/Button';
import { captureException } from '@sentry/react';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Er is een probleem opgetreden: {error.message}</p>
      <Button color="primary" onTrigger={resetErrorBoundary}>
        Ververs pagina
      </Button>
    </div>
  );
};

export const SimpleErrorBoundary: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(err) => {
        captureException(err);
      }}
      onReset={() => {
        // eslint-disable-next-line no-restricted-globals
        window.location.reload();
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
